<template>
  <v-slide-y-transition appear>
    <v-card class="pa-3 pa-md-5 mx-auto invite" light>
      <v-card-title class="text-headline text-left" v-text="'Edit User'" />
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="form.email"
            :rules="emailRules"
            color="primary"
            label="Email"
            type="email"
            outlined
            dense
            required
          />

          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            color="primary"
            label="Name"
            outlined
            dense
            required
          />

          <v-select
            v-model="form.role_code"
            :rules="roleRules"
            :items="roles"
            :return-object="true"
            label="Select Role"
            item-text="role_name"
            item-value="role_code"
            color="primary"
            outlined
            dense
            :hint="`${form.role_desc}`"
            required
            @change="setRole"
          >
            <template #item="{ index, item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.role_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          rounded
          color="dark"
          class="ma-1"
          @click.native="$emit('close')"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="ma-1"
          :loading="loading"
          @click.native="submitRegister"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/plugins/axios';

export default {
  name: 'UserAdd',
  props: {
    form: {
      type: Object,
      required: true,
    },
    roleCode: {
      type: String,
    },
    roles: {
      type: Array,
    },
  },
  data() {
    return {
      valid: true,
      showPassword: false,
      orgRules: [(v) => !!v || 'Organization is required'],
      roleRules: [(v) => !!v || 'Role is required'],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length >= 4) || 'Min 4 characters',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length >= 8) || 'Min 8 characters',
        (confirmation) =>
          confirmation === this.form.password || 'Passwords must match',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      show: false,
      show2: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      orgId: (state) => state.user.orgId,
      orgName: (state) => state.user.orgName,
    }),
  },
  methods: {
    setRole(item) {
      this.form.role_code = item.role_code;
      this.form.role_name = item.role_name;
      this.form.role_desc = item.desc;
      return item.value;
    },
    async submitRegister() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          let obj = {
            name: this.form.name,
            email: this.form.email,
            role_code: this.form.role_code,
            role_name: this.form.role_name,
            org_id: this.form.org_id,
            org_name: this.form.org_name,
            permission: this.form.permission,
            created_date: this.form.created_date,
            sub_id: this.form.sub_id || null,
            sub_name: this.form.sub_name || null,
          };
          await api.patch(`/users/${this.form.uid}`, obj);
          this.$store.dispatch('notification/add', {
            type: 'success',
            message: 'User successfully updated',
          });
          this.$emit('close');
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.$emit('close');
          this.$store.dispatch('notification/add', {
            type: 'error',
            message: `An error occurred : ${err.message}`,
          });
        }
      }
    },
  },
};
</script>
